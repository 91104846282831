import React, {useState} from 'react';
import {TrainingSession} from "../../models/TrainingSession";
import { Box, Button, Card, CardContent, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import SessionSuccessIndicator from "./SessionSuccessIndicator";
import makeStyles from '@mui/styles/makeStyles';
import SummaryView from "./SummaryView";
import ProtocolView from "./ProtocolView";
import TrialView from "./TrialView";
import HorizontalSpacer from "../common/HorizontalSpacer";
import MapView from "./MapView";
import EnvironmentView from "./EnvironmentView";
import WeatherView from "./WeatherView";
import SessionTrialsView from "./SessionTrialsView";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            marginBottom: theme.spacing(1),
        },
        cardContent: {
            "&:last-child": {
                paddingBottom: theme.spacing(2)
            }
        }
    }),
);

interface LogCardProps {
    trainingSession: TrainingSession
}

const LogCard = ({trainingSession}: LogCardProps) => {
    const classes = useStyles();
    const [showMore, setShowMore] = useState<boolean>(false);
    return (
        <Card className={classes.card} variant="outlined">
            <CardContent className={classes.cardContent}>
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={1}>
                        <SessionSuccessIndicator trainingSession={trainingSession}/>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography
                            variant="h6">{`${trainingSession.trial.order}. ${trainingSession.trial.title}`}
                        </Typography>
                        <Typography
                            variant="caption">{trainingSession.protocol.title}
                        </Typography>

                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justifyContent="flex-end">
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => setShowMore(!showMore)}
                            >
                                Show more
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    showMore &&
                    <>
                    <Box display="flex" flexDirection="column" mt={2}>
                        <SummaryView trainingSession={trainingSession}/>
                        <HorizontalSpacer space={2}/>
                        <ProtocolView protocol={trainingSession.protocol}/>
                        <HorizontalSpacer space={2}/>
                        <TrialView trial={trainingSession.trial}/>
                        <HorizontalSpacer space={2}/>
                        <EnvironmentView trainingSession={trainingSession}/>
                        <HorizontalSpacer space={2}/>
                        <WeatherView trainingSession={trainingSession}/>
                        <HorizontalSpacer space={2}/>
                        <MapView trainingSession={trainingSession}/>
                        <HorizontalSpacer space={2}/>
                        <SessionTrialsView sessionTrials={trainingSession.trials}/>
                    </Box>
                    </>
                }

            </CardContent>


        </Card>
    );
};

export default LogCard;
