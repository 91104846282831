import React, {useState} from 'react';
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import * as firebase from "firebase/app";
import 'firebase/storage';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
    },
    media: {
        height: "250px",
        width: "250px",
        borderRadius: "4px",
    },
    uploadButton: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: 10,
        borderRadius: 4
    },
    hidden: {
        display: 'none'
    }

}));

const ProtocolImage = (props) => {
    const classes = useStyles();
    const {isEditing, protocol, onUpdate} = props;
    const [isUploading, setIsUploading] = useState(false);
    const [url, setUrl] = useState(protocol.imageUrl);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const auth = useSelector(state => state.firebase.auth)

    const theme = useTheme();

    const handleUploadStart = () => {
        setIsUploading(true);

    };
    const handleUploadError = error => {
        setIsUploading(false)
        console.error(error);
    };
    const handleUploadSuccess = filename => {
        setUrl(null);
        setIsImageLoading(true);
        setIsUploading(false);
        firebase.storage()
            .ref(`users/${auth.uid}/images`)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                    onUpdate({imageUrl: url});
                    setUrl(url);
                }
            );
    };

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <Box className={classes.media}
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
            >

                <Skeleton animation="pulse" variant="rectangular"
                          className={(isImageLoading || isUploading) ? classes.media : classes.hidden}/>
                <img src={url} onLoad={() => setIsImageLoading(false)}
                     alt="Protocol illustration"
                     className={(isImageLoading || isUploading) ? classes.hidden : classes.media}/>
            </Box>

            {isEditing &&
            <CustomUploadButton
                hidden
                accept="image/*"
                storageRef={firebase.storage().ref(`users/${auth.uid}/images`)}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                randomizeFilename
                maxHeight={250}
                maxWidth={250}
                style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: "4px 8px 4px 8px",
                    borderRadius: theme.shape.borderRadius,
                    textAlign: "center",
                    marginTop: theme.spacing(1.5)
                }}>
                SELECT IMAGE
            </CustomUploadButton>
            }
        </Box>
    );
};

export default ProtocolImage;
