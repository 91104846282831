import {AppType, START_GLOBAL_LOADING, STOP_GLOBAL_LOADING} from "../actions/types";
import {Defaults} from "../../../models/Defaults";

export type AppState = {
    isLoading: boolean
    defaults?: Defaults | undefined
}

const initialState: AppState = {
    isLoading: false,
    defaults: undefined
}
export default function appReducer(state = initialState, action: AppType): AppState {
    switch (action.type) {
        case START_GLOBAL_LOADING:
            return {
                isLoading: action.payload
            }

        case STOP_GLOBAL_LOADING:
            return {
                isLoading: action.payload
            }
        default:
            return state;
    }
}
