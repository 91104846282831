import {Grid, Typography} from "@mui/material";
import React from "react";

interface SummaryItemProps {
    title: string
    value: string | number | undefined
}

const ViewItem = ({title, value}: SummaryItemProps) => {
    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <Typography variant="subtitle2">
                    {title}:
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default ViewItem;
