import React from 'react';
import {Box, Typography} from "@mui/material";
import {SessionTrial} from "../../models/TrainingSession";
import SessionTrialSuccessIndicator from "./SessionTrialSuccessIndicator";
import VerticalSpacer from "../common/VerticalSpacer";
import HorizontalSpacer from "../common/HorizontalSpacer";

interface TrialsViewProps {
    sessionTrials: SessionTrial[]
}

const SessionTrialsView = ({sessionTrials}: TrialsViewProps) => {
    return (
        <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>
            <Typography variant="h6">Trials</Typography>
            <HorizontalSpacer/>
            {
                sessionTrials.map(sessionTrial => (
                    <Box key={sessionTrial.dateTime.toMillis()} display="flex" alignItems="center" mb={1}>
                        <SessionTrialSuccessIndicator trainingSession={sessionTrial}/>
                        <VerticalSpacer space={2}/>
                        <Typography
                            variant="body1">{sessionTrial.dateTime.toDate().toLocaleString()}</Typography>
                    </Box>
                ))
            }
        </Box>
    );
};

export default SessionTrialsView;
