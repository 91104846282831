import React from 'react';
import {Chart} from "react-google-charts";
import {Box, Typography, useTheme} from "@mui/material";

interface SuccessRateDiagramProps {
    data: number[][]
}

const SuccessRateDiagram = ({data}: SuccessRateDiagramProps) => {
    const theme = useTheme();

    return (
        <Box border={1}
             p={2}
             mt={2}
             sx={{ borderRadius: 1 }}
             borderColor="grey.300"
             display="flex"
             justifyContent="center"
             flexDirection="column"
        >
            <Typography variant="h5" paragraph>Success rate</Typography>
            <Chart
                width={900}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['Session', 'Success rate', '80'],
                    ...data
                ]}
                options={{
                    backgroundColor: 'transparent',
                    legend: 'none',
                    hAxis: {
                        title: 'Session',
                        minValue: 1,

                    },
                    vAxis: {
                        title: 'Success rate',
                        maxValue: 100,
                        minValue: 0,
                    },
                    series: {
                        0: {color: theme.palette.primary.main},
                        1: {
                            type: 'line', color: '#4caf50', lineWidth: 5,

                        },
                    },
                }}
                rootProps={{'data-testid': '1'}}
            />
        </Box>
    );
};

export default SuccessRateDiagram;
