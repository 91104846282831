import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {TrainingSession} from "../../models/TrainingSession";
import ViewItem from "./ViewItem";

interface WeatherViewProps {
    trainingSession: TrainingSession
}

const WeatherView = ({trainingSession}: WeatherViewProps) => {
    return (
        <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>
            <Typography variant="h6">Weather</Typography>
            <Grid container spacing={6}>

                <Grid item xs={2}>
                    <Grid container spacing={1} direction="column" justifyContent="center"
                          alignItems="center">
                        <Grid item>
                            <img
                                src={require('../../assets/yr/png/' + trainingSession.weather.symbol)}
                                alt={trainingSession.weather.description}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {trainingSession.weather.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <ViewItem title="Temperature"
                              value={trainingSession.weather.temperature + " \u00B0C"}/>
                    <ViewItem title="Wind direction" value={trainingSession.weather.windDirection}/>
                    <ViewItem title="Wind speed"
                              value={trainingSession.weather.windSpeed + 'mps'}/>
                    <ViewItem title="Humidity" value={trainingSession.weather.humidity + '%'}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WeatherView;
