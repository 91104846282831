import React from 'react';
import {isEmpty, useFirebase} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import {Box, Typography} from "@mui/material";
import GoogleButton from "react-google-button";
import {Redirect} from 'react-router-dom';

const LoginPage = () => {
    const firebase = useFirebase()
    const auth = useSelector<RootState>(state => state.firebase.auth)

    const loginWithGoogle = () => firebase.login({provider: 'google', type: 'popup'});


    if (isEmpty(auth)) return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
            <Box m={4} display="flex" alignItems="center" flexDirection="column">
                <Typography variant="h3" color="primary">Login to Logs4Dogs</Typography>
            </Box>
            <GoogleButton onClick={loginWithGoogle}/>
        </Box>
    );

    return <Redirect to="/me/dashboard"/>;
};

export default LoginPage;
