import React from 'react';
import {Box, Typography} from "@mui/material";
import {SessionTrialUtilities} from "../../utilities/SessionTrialUtilities";
import {TrainingSession} from "../../models/TrainingSession";

interface ProtocolTotalsProps {
    trainingSessions: TrainingSession[]
}

const ProtocolTotals = ({trainingSessions}: ProtocolTotalsProps) => {

    return (
        <Box border={1}
             p={2}
             mt={2}
             sx={{ borderRadius: 1 }}
             borderColor="grey.300"
             display="flex"
             justifyContent="space-between"
        >

            <Box>
                <Typography variant="subtitle1">Training sessions</Typography>
                <Typography variant="h6">{trainingSessions.length}</Typography>
            </Box>
            <Box>
                <Typography variant="subtitle1">Success rate</Typography>
                <Typography
                    variant="h6">{SessionTrialUtilities.getTotalPercentage(trainingSessions).toFixed(2)}%</Typography>
            </Box>
            <Box>
                <Typography variant="subtitle1">Training time</Typography>
                <Typography
                    variant="h6">{SessionTrialUtilities.getTotalTrainingTimeFormatted(trainingSessions)}</Typography>
            </Box>

        </Box>
    );
};

export default ProtocolTotals;
