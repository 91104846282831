import React from 'react';
import {Box, Typography} from "@mui/material";
import {Chart} from "react-google-charts";

interface TrendDiagramProps {
    data: number[][]
}

const TrendDiagram = ({data}: TrendDiagramProps) => {

    return (
        <Box border={1}
             p={2}
             mt={2}
             sx={{ borderRadius: 1 }}
             borderColor="grey.300"
             display="flex"
             justifyContent="center"
             flexDirection="column"
        >
            <Typography variant="h5" paragraph>Trend</Typography>
            <Chart
                width={900}
                chartType="ScatterChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['x', 'success rate'],
                    ...data
                ]}
                options={{
                    legend: 'none',
                    backgroundColor: 'transparent',
                    hAxis: {
                        title: 'Session'
                    },
                    vAxis: {
                        title: 'Success rate',
                        maxValue: 100,
                        minValue: 0,
                    },
                    series: {
                        0: {curveType: 'function', color: '#4caf50',},
                    },
                    crosshair: {trigger: 'both', orientation: 'both'},
                    trendlines: {
                        0: {
                            type: 'exponential',
                            color: '#4caf50',
                            lineWidth: 10,
                            opacity: 0.2,
                        },
                    },
                }}
                rootProps={{'data-testid': '1'}}
            />
        </Box>
    );
};

export default TrendDiagram;
