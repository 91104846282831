import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import DashboardPage from "../../features/dashboard/DashboardPage";
import MyProtocolsPage from "../../features/protocol/MyProtocolsPage";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../App";
import {isEmpty, isLoaded} from "react-redux-firebase";
import {startGlobalLoading, stopGlobalLoading} from "../../data/redux/actions/actions";
import MyDogsPage from "../../features/dogs/MyDogsPage";
import LogPage from "../../features/training-sessions/LogPage";
import AnalyticsPage from "../../features/training-sessions/AnalyticsPage";
import EnvironmentPage from "../../features/settings/EnvironmentPage";


const Routes = () => {
    const auth = useSelector<RootState>(state => state.firebase.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        isLoaded(auth) ? dispatch(stopGlobalLoading()) : dispatch(startGlobalLoading());
    }, [auth, dispatch]);

    if (!isLoaded(auth)) return <div/>;
    if (isEmpty(auth)) return <Redirect to={"/"}/>;
    return (
        <>
            <Route exact path="/me/dashboard" component={DashboardPage}/>
            <Route exact path="/me/protocols" component={MyProtocolsPage}/>
            <Route exact path="/me/dogs" component={MyDogsPage}/>
            <Route exact path="/me/training-sessions/logs" component={LogPage}/>
            <Route exact path="/me/training-sessions/analytics" component={AnalyticsPage}/>
            <Route exact path="/me/settings/environments" component={EnvironmentPage}/>
        </>
    );
};

export default Routes;
