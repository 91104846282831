import React, {useState} from 'react';
import {Box, List} from "@mui/material";
import TrialListItem from "./TrialListItem";
import NewTrialDialog from "./NewTrialDialog";
import {MyProtocol, Trial} from "../../models/Protocol";
import {isLoaded, useFirestore} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import TrialAddButton from "../trial-add-button/TrialAddButton";

type TrialListProps = {
    protocol: MyProtocol,
    trials: Trial[]
}
const TrialList = ({protocol, trials}: TrialListProps) => {
    const [newTrialOpen, setNewTrialOpen] = useState<boolean>(false);
    const [order, setOrder] = useState<number>(0);
    const auth: any = useSelector<RootState>(state => state.firebase.auth)

    const userProtocols = useFirestore()
        .collection('users')
        .doc(auth.uid)
        .collection('protocols');


    const onSaveNewTrial = (trial: Trial) => {
        setNewTrialOpen(false);
        userProtocols.doc(protocol.id).collection('trials').add(trial).then(() => console.log('added trial'));
    }

    const onCancelNewTrial = () => {
        setNewTrialOpen(false);
    }

    return (
        <Box p={2} mr={2}>
            <NewTrialDialog isOpen={newTrialOpen} onSave={onSaveNewTrial}
                            onCancel={onCancelNewTrial} order={order}/>
            <List>
                <TrialAddButton onClick={() => {
                    setNewTrialOpen(true);
                    setOrder(0)
                }}/>
                {
                    isLoaded(trials) && trials.map((trial) => (
                    trial.id && <TrialListItem key={trial.id}
                                               trial={trial}
                                               protocolId={protocol.id}
                                               onAddTrail={() => {
                                                   setNewTrialOpen(true);
                                                   setOrder(trial.order + 1)
                                               }}/>
                ))
                }
            </List>
        </Box>


    );
};

export default TrialList;
