import React from 'react';
import {GoogleMap, Marker} from "@react-google-maps/api";
import {TrainingSession} from "../../models/TrainingSession";
import {Box} from "@mui/material";

interface MapViewProps {
    trainingSession: TrainingSession
}

const MapView = ({trainingSession}: MapViewProps) => {
    return (
        <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>

            <GoogleMap
                mapContainerStyle={{
                    height: '250px',
                }}
                center={{
                    lat: trainingSession.location.lat,
                    lng: trainingSession.location.lon
                }}
                zoom={15}
            >

                <>
                    <Marker
                        position={{
                            lat: trainingSession.location.lat,
                            lng: trainingSession.location.lon,
                        }}
                        title={trainingSession.startTime.toDate().toLocaleString()}
                    />
                </>
            </GoogleMap>
        </Box>
    );
};

export default MapView;
