import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmDialogProps =
    {
        title: string,
        message: string,
        onConfirm: () => void,
        onNotConfirm: () => void,
        confirmButtonText?: string,
        notConfirmButtonText?: string,
        open: boolean
    }

export default function ConfirmDialog(
    {
        confirmButtonText = "Delete",
        notConfirmButtonText = "Cancel",
        title,
        open,
        message,
        onConfirm,
        onNotConfirm
    }: ConfirmDialogProps) {
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onNotConfirm} color="primary" autoFocus>
                        {notConfirmButtonText}
                    </Button>
                    <Button onClick={onConfirm} color="primary">
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
