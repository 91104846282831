import React, {useEffect, useState} from 'react';
import PageTitle from "../../components/common/PageTitle";
import {Box, Button} from "@mui/material";
import AddIcon from '@mui/icons-material/AddRounded';
import NewDogDialog from "../../components/my-dogs-add/NewDogDialog";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../App";
import {isEmpty, isLoaded, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {Dog} from "../../models/Dog";
import {startGlobalLoading, stopGlobalLoading} from "../../data/redux/actions/actions";
import MyDogCard from "../../components/my-dogs-card/MyDogCard";


const MyDogsPage = () => {
    const [newDogOpen, setNewDogOpen] = useState<boolean>(false);
    const auth: any = useSelector<RootState>(state => state.firebase.auth);
    const dispatch = useDispatch();

    const userDogs = useFirestore()
        .collection('users')
        .doc(auth.uid)
        .collection('dogs');

    useFirestoreConnect([
        {
            collection: 'users',
            doc: auth.uid,
            subcollections: [{collection: 'dogs'}],
            orderBy: ['name', 'asc'],
            storeAs: 'dogs'
        }
    ]);
    const dogs = useSelector((state: RootState) => state.firestore.ordered['dogs']);

    const onSaveNewDog = (dog: Dog) => {
        userDogs
            .add(dog)
            .then(() => console.log('Dog added'));
        setNewDogOpen(false);
    };

    const onCancelNewDog = () => {
        setNewDogOpen(false);
    };

    const onUpdateDog = (dog: Dog) => {
        userDogs.doc(dog.id).update(dog).then(() => {
        });
    };

    const onDeleteDog = (dogId: string) => {
        userDogs
            .doc(dogId)
            .delete()
            .then(() => {

        });
    }

    useEffect(() => {
        isLoaded(dogs) ? dispatch(stopGlobalLoading()) : dispatch(startGlobalLoading());
    }, [dogs, dispatch]);
    return (
        <Box>
            <NewDogDialog isOpen={newDogOpen}
                          onCancel={onCancelNewDog}
                          onSave={onSaveNewDog}
            />
            <PageTitle title="My Dogs">
                <Button startIcon={<AddIcon/>}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setNewDogOpen(true)
                        }}>
                    Add Dog
                </Button>
            </PageTitle>
            <Box>
                {
                    !isEmpty(dogs) &&
                    Object.values<Dog>(dogs).map(dog => (
                            <MyDogCard key={dog.id} dog={dog} onDeleteDog={onDeleteDog} onUpdateDog={onUpdateDog}/>
                        )
                    )
                }
            </Box>
        </Box>
    );
};

export default MyDogsPage;
