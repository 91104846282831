export const START_GLOBAL_LOADING = 'START_GLOBAL_LOADING';
export const STOP_GLOBAL_LOADING = 'STOP_GLOBAL_LOADING';

interface StartGlobalLoadingType {
    type: typeof START_GLOBAL_LOADING
    payload: boolean
}

interface StopGlobalLoadingType {
    type: typeof STOP_GLOBAL_LOADING
    payload: boolean
}


export type AppType = StartGlobalLoadingType | StopGlobalLoadingType;
