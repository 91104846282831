import React, {ChangeEvent} from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

interface MyDogCardChipNumberProps {
    chipNumber: string | undefined | null
    edit: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const MyDogCardChipNumber = ({chipNumber, edit, onChange}: MyDogCardChipNumberProps) => {
    return (
        <Box mt={1.5}>
            {edit ?
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Chip number"
                    value={chipNumber ? chipNumber : ''}
                    onChange={onChange}
                />
                :
                <>
                    <Typography component="p" variant="caption" color="textSecondary">
                        Chip number:
                    </Typography>
                    <Typography component="p" variant="caption">
                        {chipNumber}
                    </Typography>
                </>
            }
        </Box>
    );
};

export default MyDogCardChipNumber;
