import React from 'react';
import {Box} from "@mui/material";

interface Props {
    space?: number
}
const VerticalSpacer = ({space = 1}: Props) => {
    return <Box mr={space}/>;
};

export default VerticalSpacer;
