import React, {useState} from 'react';
import {Box, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import {MyProtocol} from "../../models/Protocol";
import Image from '../common/Image';
import MyProtocolCardTitle from "./MyProtocolCardTitle";
import MyProtocolCardDescription from "./MyProtocolCardDescription";
import {useFirestore} from "react-redux-firebase";
import ConfirmDialog from "../common/ConfirmDialog";
import ProtocolTimestampView from "./ProtocolTimestampView";
import {useSelector} from "react-redux";
import {RootState} from "../../App";

interface MyProtocolCardContentProps {
    protocol: MyProtocol,
}


const MyProtocolCardContent = ({protocol}: MyProtocolCardContentProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(protocol.title);
    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const [description, setDescription] = useState<string>(protocol.description);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const userProtocol = useFirestore()
        .collection('users')
        .doc(auth.uid)
        .collection('protocols');


    const onEdit = () => {
        if (isEditing) {
            userProtocol.doc(protocol.id).update({
                title: title,
                description: description
            }).then(() => console.log('Saved'));
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    }

    const onUploadImage = (myProtocol: MyProtocol) => {
        userProtocol.doc(protocol.id).update(myProtocol).then(() => console.log('image updated'));
    }

    const onDelete = () => {
        userProtocol.doc(protocol.id).delete().then(() => console.log('deleted'));
    }

    return (
        <Box>
            <ConfirmDialog
                title="Delete protocol?"
                message="Do you really want to delete the protocol?"
                onConfirm={onDelete}
                onNotConfirm={() => setShowConfirmDialog(false)}
                open={showConfirmDialog}
            />
            <Box display="flex">
                <Image isEditing={isEditing} protocol={protocol}
                       onUpdate={(p: MyProtocol) => onUploadImage(p)}/>
                <Box
                    width={1}
                    ml={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >

                    <Box>
                        <MyProtocolCardTitle
                            edit={isEditing}
                            title={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <MyProtocolCardDescription
                            edit={isEditing}
                            description={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                        <ProtocolTimestampView createdAt={protocol.createdAt}
                                               lastModified={protocol.lastModified}/>
                        <Box>
                            <IconButton
                                aria-label="edit"
                                onClick={onEdit}
                                disabled={protocol.id === undefined}
                                size="large">
                                {isEditing ? <SaveIcon/> : <EditIcon/>}
                            </IconButton>
                            <IconButton
                                aria-label="delete"
                                disabled={isEditing || protocol.id === undefined}
                                onClick={() => setShowConfirmDialog(true)}
                                size="large">
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default MyProtocolCardContent;
