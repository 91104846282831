import React, {useState} from 'react';
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import * as firebase from "firebase/app";
import 'firebase/storage';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
    },
    media: {
        height: 345,
        width: 345,
    },
    uploadButton: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: 10,
        borderRadius: 4
    },
    hidden: {
        display: 'none'
    }

}));

const DogImage = (props) => {
    const classes = useStyles();
    const {isEditing, dog, onUpdate} = props;
    const [isUploading, setIsUploading] = useState(false);
    const defaults = useSelector((state) => state.firestore.data.defaults.defaults);
    const [url, setUrl] = useState(dog.image ? dog.image : defaults.dogImageUrl);
    const [isImageLoading, setIsImageLoading] = useState(!!url);
    const auth = useSelector(state => state.firebase.auth)

    const theme = useTheme();

    const handleUploadStart = () => {
        setIsUploading(true);

    };
    const handleUploadError = error => {
        setIsUploading(false)
        console.error(error);
    };
    const handleUploadSuccess = filename => {
        setUrl(null);
        setIsImageLoading(true);
        setIsUploading(false);
        firebase.storage()
            .ref(`users/${auth.uid}/images`)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                    onUpdate({...dog, image: url});
                    setUrl(url);
                }
            );
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>

            <Box className={classes.media}
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
            >
                <Skeleton animation="pulse" variant="rectangular"
                          className={(isImageLoading || isUploading)
                              ? classes.media : classes.hidden}/>
                <img src={url} onLoad={() => setIsImageLoading(false)}
                     alt="Dog"
                     className={(isImageLoading || isUploading)
                         ? classes.hidden : classes.media}/>
            </Box>

            {isEditing &&
            <CustomUploadButton
                accept="image/*"
                storageRef={firebase.storage().ref(`users/${auth.uid}/images`)}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                randomizeFilename
                maxHeight={250}
                maxWidth={250}
                style={{
                    color: theme.palette.primary.contrastText,
                    padding: "4px 32px 4px 32px",
                    margin: theme.spacing(1),
                    borderRadius: theme.shape.borderRadius,
                    width: 'fit-content',
                    textAlign: "center",
                    backgroundColor: theme.palette.grey["700"],
                    cursor: "pointer",
                }}>

                {"Select image".toUpperCase()}
            </CustomUploadButton>
            }

        </Box>
    );
};

export default DogImage;
