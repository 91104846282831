import React, {useState} from 'react';
import { Box, Button, Card, CardContent, Divider, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {MyProtocol, Trial} from "../../models/Protocol";
import makeStyles from '@mui/styles/makeStyles';
import KeyArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyArrowUpIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import TrialList from "./TrialList";
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import MyProtocolCardContent from "./MyProtocolCardContent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
        },
        card: {
            marginBottom: theme.spacing(2)
        }
    }),
);

interface MyProtocolCardProps {
    protocol: MyProtocol
}

const MyProtocolCard = ({protocol}: MyProtocolCardProps) => {
    const classes = useStyles();
    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const [showTrialList, setShowTrialList] = useState(false);

    useFirestoreConnect([
        {
            collection: 'users',
            doc: auth.uid,
            subcollections: [{
                collection: 'protocols',
                doc: protocol.id,
                subcollections: [{collection: 'trials'}],
            }],
            storeAs: protocol.id,
            orderBy: [
                ['order', 'asc'],
                ['id', 'asc'],
            ]
        }
    ]);

    const trials: Trial[] = useSelector((state: RootState) => state.firestore.ordered[protocol.id]);

    // TODO: Hope we can fix this in the future. See https://logs4dogs.atlassian.net/browse/L4D-42 for more information.
    const fixTrialsList = () => {
        return trials
            .filter(trial => trial.id !== null)
            .sort((a, b) => a.order < b.order ? -1 : 1)
    }
    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <MyProtocolCardContent protocol={protocol}/>
            </CardContent>
            <Box width={1} pl={2} pr={2}>
                <Divider/>
            </Box>
            <Box display="flex" justifyContent="flex-end" pl={2} pr={2} pt={1.5}
                 pb={showTrialList ? 3 : 1.5}>
                <Button
                    endIcon={showTrialList ? <KeyArrowUpIcon/> : <KeyArrowDownIcon/>}
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowTrialList(!showTrialList)}
                    disabled={!isLoaded(trials)}
                >
                    {`Trials (${protocol.trialCount || 0})`}
                </Button>
            </Box>
            {showTrialList && <TrialList protocol={protocol}
                                         trials={fixTrialsList()}/>}

        </Card>
    );
};

export default MyProtocolCard;
