import React, {useState} from 'react';
import {Dog} from "../../models/Dog";
import { Card, IconButton, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import CardContent from "@mui/material/CardContent";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import MyDogCardTitle from "./MyDogCardTitle";
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import MyDogCardDateOfBirth from "./MyDogCardDateOfBirth";
import MyDogCardRegNumber from "./MyDogCardRegNumber";
import MyDogCardChipNumber from "./MyDogCardChipNumber";
import * as firebase from "firebase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DogImage from "./DogImage";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 0,
        },
        card: {
            marginBottom: theme.spacing(2),
            maxWidth: 760,
            minHeight: 345,
        },
        image: {
            width: 345,
            height: 345,
        },
    }),
);


interface MyDogCardProps {
    dog: Dog
    onDeleteDog: (dogId: string) => void
    onUpdateDog: (dog: Dog) => void
}

const MyDogCard = ({dog, onDeleteDog, onUpdateDog}: MyDogCardProps) => {

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(dog.name);
    const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(dog.dateOfBirth?.toDate());
    const [regNumber, setRegNumber] = useState<string | undefined | null>(dog.registrationNumber);
    const [chipNumber, setChipNumber] = useState<string | undefined | null>(dog.chipNumber);


    const classes = useStyles();

    const onUpdate = () => {

        if (isEditing) {
            onUpdateDog({
                ...dog,
                name: title,
                dateOfBirth: dateOfBirth ? firebase.firestore.Timestamp.fromDate(dateOfBirth) : null,
                registrationNumber: regNumber ? regNumber : null,
                chipNumber: chipNumber ? chipNumber : null
            });
        }

        setIsEditing(!isEditing);
    };

    const onDelete = () => {
        if (dog.id) {
            onDeleteDog(dog.id);
        }
        handleClose();
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <Card className={classes.card}>
                <Box display='flex' flexDirection='row'>

                    <DogImage isEditing={isEditing} dog={dog} onUpdate={onUpdateDog}/>

                    <Box display="flex" flexDirection="column" justifyContent="space-between"
                         width={1}>
                        <CardContent className={classes.cardContent}>
                            <MyDogCardTitle name={title} edit={isEditing}
                                            onChange={(e) => setTitle(e.target.value)}/>
                            <MyDogCardDateOfBirth dateOfBirth={dateOfBirth} edit={isEditing}
                                                  onChange={setDateOfBirth}/>
                            <MyDogCardRegNumber regNumber={regNumber} edit={isEditing}
                                                onChange={(e) => setRegNumber(e.target.value)}/>
                            <MyDogCardChipNumber chipNumber={chipNumber} edit={isEditing}
                                                 onChange={(e) => setChipNumber(e.target.value)}/>

                        </CardContent>
                        <Box display="flex" justifyContent="flex-end" p={1}>
                            <IconButton aria-label="edit" onClick={onUpdate} size="large">
                                {isEditing ? <SaveIcon/> : <EditIcon/>}
                            </IconButton>
                            <IconButton
                                aria-label="delete"
                                disabled={isEditing}
                                onClick={handleClickOpen}
                                size="large">
                                <DeleteIcon/>
                            </IconButton>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle
                                    id="alert-dialog-title">{`Are you sure you want to delete ${dog.name}?`}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        If you delete the dog all data about the dog is deleted.
                                        That includes the training sessions. It is not possible to
                                        get this data back.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={onDelete}
                                            color="primary" autoFocus>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </Box>

                    </Box>
                </Box>
            </Card>

        </div>
    );
};

export default MyDogCard;
