import React, {FunctionComponent} from 'react';
import {Box, Typography} from "@mui/material";

type PageTitleProps = {
    title: string,
}
const PageTitle: FunctionComponent<PageTitleProps> = ({title, children}: React.PropsWithChildren<PageTitleProps>) =>

    <Box display="flex" justifyContent="space-between" mb={4.5}>
        <Typography variant='h4' color="textPrimary" align="left">
            {title}
        </Typography>
        {children}
    </Box>


export default PageTitle;
