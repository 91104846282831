import React, {useState} from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import {Dog} from "../../models/Dog";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


interface NewDogDialogProps {
    isOpen: boolean,
    onSave: (dog: Dog) => void,
    onCancel: () => void
}


const NewDogDialog = ({isOpen, onCancel, onSave}: NewDogDialogProps) => {
    const [title, setTitle] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [regNumber, setRegNumber] = useState<string>("");
    const [chipNumber, setChipNumber] = useState<string>("");

    const onAdd = () => {
        onSave({
            name: title,
            dateOfBirth: dateOfBirth ? firebase.firestore.Timestamp.fromDate(dateOfBirth) : null,
            registrationNumber: regNumber,
            chipNumber: chipNumber
        });
    };
    return (
        <Dialog
            fullWidth
            open={isOpen}
            onClose={onCancel}
            aria-labelledby="form-dialog-title"
            disableEscapeKeyDown>
            <DialogTitle id="form-dialog-title">New Dog</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    autoFocus
                    label="Name"
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
                <Box height="8px"/>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Basic example"
                        value={dateOfBirth}
                        onChange={(newValue) => {
                            setDateOfBirth(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <Box height="8px"/>
                <TextField
                    variant="outlined"
                    label="Registration number"
                    fullWidth
                    onChange={(e) => setRegNumber(e.target.value)}
                    value={regNumber}
                />
                <Box height="8px"/>
                <TextField
                    variant="outlined"
                    label="Chip number"
                    fullWidth
                    onChange={(e) => setChipNumber(e.target.value)}
                    value={chipNumber}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onAdd} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewDogDialog;
