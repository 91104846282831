import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    TextField
} from "@mui/material";
import PageTitle from "../../components/common/PageTitle";
import AddIcon from "@mui/icons-material/AddRounded";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../App";
import {isLoaded, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {startGlobalLoading, stopGlobalLoading} from "../../data/redux/actions/actions";
import {Environment} from "../../models/Environment";
import ConfirmDialog from "../../components/common/ConfirmDialog";


const EnvironmentPage = () => {

    const [newEnvironmentOpen, setNewEnvironmentOpen] = useState<boolean>(false);
    const [deleteEnvironmentOpen, setDeleteEnvironmentOpen] = useState<boolean>(false);
    const [newEnvironment, setNewEnvironment] = useState<string>("");
    const [selectedEnvironment, setSelectedEnvironment] = useState<string | undefined>("");
    const auth: any = useSelector<RootState>(state => state.firebase.auth);
    const dispatch = useDispatch();

    const userEnvironments = useFirestore()
        .collection('users')
        .doc(auth.uid)
        .collection('environments');

    useFirestoreConnect([
        {
            collection: 'users',
            doc: auth.uid,
            subcollections: [{collection: 'environments'}],
            orderBy: ['title', 'asc'],
            storeAs: 'environments'
        }
    ]);
    const environments = useSelector((state: RootState) => state.firestore.ordered['environments']);

    const onSaveNewEnvironment = () => {

        userEnvironments
            .add({title: newEnvironment})
            .then(() => console.log('Environments added'));
        setNewEnvironmentOpen(false);
    };

    const onCancelNewEnvironment = () => {
        setNewEnvironmentOpen(false);
    };

    const onDeleteEnvironment = () => {
        userEnvironments
            .doc(selectedEnvironment)
            .delete()
            .then(() => {
                setDeleteEnvironmentOpen(false);
            });
    }

    useEffect(() => {
        isLoaded(environments) ? dispatch(stopGlobalLoading()) : dispatch(startGlobalLoading());
    }, [environments, dispatch]);

    return (
        <Box>
            <PageTitle title="Environments">
                <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                        setNewEnvironmentOpen(true)
                    }}>
                    <AddIcon/>
                </Fab>
            </PageTitle>
            <Box
                p={4}
                border={1}
                borderRadius={1}
                borderColor="grey.400"
                flexWrap="wrap"
                display="flex"
                justifyContent="center"
            >
                {
                    isLoaded(environments) && environments.map((environment: Environment) => (
                        <Chip
                            key={environment.id}
                            label={environment.title}
                            variant="outlined"
                            onDelete={() => {
                                setSelectedEnvironment(environment.id);
                                setDeleteEnvironmentOpen(true);
                            }}
                            style={{margin: "4px"}}
                        />

                    ))
                }
            </Box>

            <ConfirmDialog
                message="Do you really want to delete the environment?"
                onConfirm={onDeleteEnvironment}
                onNotConfirm={onCancelNewEnvironment}
                open={deleteEnvironmentOpen}
                title="Delete environment?"
            />
            <Dialog open={newEnvironmentOpen} onClose={onCancelNewEnvironment}
                    aria-labelledby="form-dialog-title">
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Environment"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setNewEnvironment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelNewEnvironment} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSaveNewEnvironment} color="primary"
                            disabled={newEnvironment.length === 0}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default EnvironmentPage;
