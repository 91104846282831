import React from 'react';
import {TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import 'date-fns';
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

interface MyDogCardDateOfBirthProps {
    dateOfBirth?: Date
    edit: boolean
    onChange: (date: Date, value?: string | null) => void

}

const MyDogCardDateOfBirth = ({dateOfBirth, edit, onChange}: MyDogCardDateOfBirthProps) => {

    return (

        <Box mt={1.5}>
            {edit ?
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Basic example"
                        value={dateOfBirth}
                        onChange={(newValue) => {
                            if (newValue) {
                                onChange(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                :
                <>
                    <Typography component="p" variant="caption" color="textSecondary">
                        Date of birth:
                    </Typography>
                    <Typography component="p" variant="caption">
                        {dateOfBirth ? dateOfBirth.toLocaleDateString() : 'n/a'}
                    </Typography>
                </>
            }
        </Box>

    );
};

export default MyDogCardDateOfBirth;
