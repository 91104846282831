import React from 'react';
import {Box, Grid, Typography} from "@mui/material";

interface StatisticsCardProps {
    stats: number | string
    title: string
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    borderColor?: string
}

const TotalsCard = ({stats, title, xs = 6, borderColor = "grey.500"}: StatisticsCardProps) => {
    return (
        <Grid item xs={xs}>
            <Box
                p={3}
                border={1}
                borderColor={borderColor}
                sx={{ borderRadius: 1 }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
                minHeight={200}
                textAlign="center"
            >
                <Typography variant="h2">{stats}</Typography>
                <Typography variant="h5">{title}</Typography>
            </Box>
        </Grid>
    );
};

export default TotalsCard;
