import React, {ChangeEvent, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TrialForm from "./TrialForm";
import {Trial} from "../../models/Protocol";

interface NewTrialDialogProps {
    isOpen: boolean,
    onSave: (trial: Trial) => void,
    onCancel: () => void,
    order: number
}

const defaultTrial: Trial = {
    order: 0,
    criteriaDescription: '',
    cue: '',
    onLeash: false,
    resetDescription: '',
    title: '',
    trialCount: 10,
    trainerDistance: 0,
    trainerPosition: ''
}

const NewTrialDialog = ({isOpen, onCancel, onSave, order}: NewTrialDialogProps) => {
    const [trial, setTrial] = useState<Trial>(defaultTrial);

    const onUpdateTrial = (e: ChangeEvent<HTMLInputElement>) => {
        let value;
        if (e.target.type === 'text' || e.target.type === 'textarea') {
            value = e.target.value;
        }
        if (e.target.type === 'number') {
            value = parseInt(e.target.value, 10);
        }
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        }
        setTrial({...trial, [e.target.id]: value})

    }
    return (
        <Dialog fullWidth open={isOpen} onClose={onCancel}
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New Trial</DialogTitle>
            <DialogContent>

                <TrialForm trial={trial} onUpdate={onUpdateTrial}/>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onSave({...trial, order: order})} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewTrialDialog;
