import React from 'react';
import './App.css';
import {
    createTheme,
    CssBaseline,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
} from "@mui/material";
import Scaffold from "./components/common/Scaffold";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {FirebaseReducer, firebaseReducer, ReactReduxFirebaseProvider} from "react-redux-firebase";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import firebaseConfig from "./config/firebase-config";
import {createFirestoreInstance, firestoreReducer} from "redux-firestore";
import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/analytics'
import {MyProtocol} from "./models/Protocol";
import logger from "redux-logger";
import appReducer, {AppState} from "./data/redux/reducers/app";
import LoginPage from "./features/login/LoginPage";
import thunk from 'redux-thunk';
import NotFound from "./components/common/NotFound";



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



const theme = createTheme({
    palette: {
        primary: {
            main: '#005073',
        },
        secondary: {
            main: '#fff'
        },
    }
});

interface Schema {
    protocols: MyProtocol
}

export interface RootState {
    firebase: FirebaseReducer.Reducer<{}, Schema>
    firestore: any,
    app: AppState
}


firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();

const rootReducer = combineReducers<RootState>({
    firebase: firebaseReducer,
    firestore: firestoreReducer, // <- needed if using firestore
    app: appReducer,
})

const middleware = compose(
    applyMiddleware(logger),
    applyMiddleware(thunk)
);
const initialState = {}
const store = createStore(rootReducer, initialState, middleware)

const rrfConfig = {
    userProfile: 'profiles',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
};

function App() {

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Provider store={store}>
                    <ReactReduxFirebaseProvider {...rrfProps}>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/me/" component={Scaffold}/>
                                <Route exact path="/" component={LoginPage}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </BrowserRouter>
                    </ReactReduxFirebaseProvider>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
