import {AppType, START_GLOBAL_LOADING, STOP_GLOBAL_LOADING} from "./types";

export function startGlobalLoading(): AppType {
    return {
        type: START_GLOBAL_LOADING,
        payload: true
    }
}

export function stopGlobalLoading(): AppType {
    return {
        type: STOP_GLOBAL_LOADING,
        payload: false
    }
}
