import React from 'react';
import {Box, Typography} from "@mui/material";
import { Skeleton } from '@mui/material';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import HorizontalSpacer from "../common/HorizontalSpacer";

type ProtocolTimestampViewProps =
    {
        createdAt: firebase.firestore.Timestamp | undefined,
        lastModified: firebase.firestore.Timestamp | undefined
    }


const ProtocolTimestampView = ({createdAt, lastModified}: ProtocolTimestampViewProps) => {
    return <Box>
        <Box width={1} display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
                Created:
            </Typography>

            {createdAt ? <Typography variant="caption"
                                     color="textSecondary">{createdAt?.toDate().toLocaleString()}</Typography> :
                <Skeleton/>}

        </Box>
        <HorizontalSpacer/>
        <Box width={1} display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
                Modified:
            </Typography>
            {lastModified ? <Typography variant="caption"
                                        color="textSecondary">{lastModified?.toDate().toLocaleString()}</Typography> :
                <Skeleton/>}
        </Box>
    </Box>;
};

export default ProtocolTimestampView;
