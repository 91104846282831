import React, {ChangeEvent} from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {Trial} from "../../models/Protocol";
import VerticalSpacer from "../common/VerticalSpacer";

interface TrialFormProps {
    trial: Trial
    onUpdate?: (e: ChangeEvent<HTMLInputElement>) => void
}

const TrialForm = ({trial, onUpdate}: TrialFormProps) => {

    return (
        <Box>
            <form noValidate autoComplete="off">
                <TextField
                    id="title"
                    label="Title"
                    value={trial.title}
                    onChange={onUpdate}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    id="criteriaDescription"
                    label="Criteria"
                    value={trial.criteriaDescription}
                    onChange={onUpdate}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={5}
                />
                <TextField
                    id="resetDescription"
                    label="Reset description"
                    value={trial.resetDescription}
                    onChange={onUpdate}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    multiline
                    //maxRows={10}
                    rows={5}
                />
                <TextField
                    id="trainerPosition"
                    label="Trainer position"
                    value={trial.trainerPosition}
                    onChange={onUpdate}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    id="cue"
                    label="Cue"
                    value={trial.cue}
                    onChange={onUpdate}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                    <TextField
                        id="trialCount"
                        label="Trial count"
                        value={trial.trialCount}
                        onChange={onUpdate}
                        margin="normal"
                        variant="outlined"
                        type="number"
                    />
                    <VerticalSpacer space={2}/>
                    <TextField
                        id="trainerDistance"
                        label="Trainer distance"
                        value={trial.trainerDistance}
                        onChange={onUpdate}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <Typography
                                    variant="body2"
                                    color="textSecondary">
                                    <i>m</i>
                                </Typography>
                            </InputAdornment>,
                        }}
                    />
                    <VerticalSpacer space={2}/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={trial.onLeash}
                                onChange={onUpdate}
                                id="onLeash"
                                color={"default"}
                            />
                        }
                        label="On leash"
                    />
                </Box>
            </form>
        </Box>
    );
};

export default TrialForm;
