import React from 'react';
import {Box, Typography} from "@mui/material";
import {Chart} from "react-google-charts";

interface CalenderDiagramProps {
    data: (number | Date)[][]
}

const CalenderDiagram = ({data}: CalenderDiagramProps) => {

    return (
        <Box border={1}
             p={2}
             mt={2}
             sx={{ borderRadius: 1 }}
             borderColor="grey.300"
             display="flex"
             justifyContent="center"
             flexDirection="column"
        >
            <Typography variant="h5" paragraph>Calender</Typography>
            <Chart
                width={1000}
                chartType="Calendar"
                loader={<div>Loading Chart</div>}
                data={[
                    [{type: 'date', id: 'Date'}, {type: 'number', id: '%'}],
                    ...data]}
                options={{
                    colorAxis: {minValue: 0, maxValue: 100, colors: ['#F44336', '#4caf50']}
                }}
                rootProps={{'data-testid': '1'}}
            />
        </Box>
    );
};

export default CalenderDiagram;
