import React, {useEffect, useState} from 'react';
import {GoogleMap, LoadScript, Marker, MarkerClusterer} from "@react-google-maps/api";
import {isEmpty} from "react-redux-firebase";
import {TrainingSession} from "../../models/TrainingSession";
import {v4 as uuidv4} from 'uuid';


interface MapOverviewProps {
    trainingSessions: TrainingSession[]
}

const MapOverview = ({trainingSessions}: MapOverviewProps) => {
    const [currentPosition, setCurrentPosition] = useState<object>({lat: 0, lng: 0});
    const [zoomLevel, setZoomLevel] = useState<number>(2);

    const options = {
        imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(position => {
            setCurrentPosition({lat: position.coords.latitude, lng: position.coords.longitude});
            setZoomLevel(6);
        }, error => {
            !isEmpty(trainingSessions) && setCurrentPosition({
                lat: trainingSessions[0].location.lat,
                lng: trainingSessions[0].location.lon
            });
        })
    }, [trainingSessions])
    return (
        <div>
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_API_KEY || ""}
            >
                <GoogleMap
                    mapContainerStyle={{
                        height: '500px',
                    }}
                    center={currentPosition}
                    zoom={zoomLevel}
                >
                    <MarkerClusterer options={options}>
                        {(clusterer) =>
                            trainingSessions
                                .map(trainingSession => ({
                                    lat: trainingSession.location.lat,
                                    lng: trainingSession.location.lon
                                }))
                                .map((location) => (
                                    <Marker key={uuidv4()} position={location}
                                            clusterer={clusterer}/>
                                ))
                        }
                    </MarkerClusterer>
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapOverview;
