import React, {ChangeEvent} from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

interface MyDogCardRegNumberProps {
    regNumber: string | undefined | null
    edit: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const MyDogCardRegNumber = ({regNumber, edit, onChange}: MyDogCardRegNumberProps) => {
    return (
        <Box mt={1.5}>
            {edit ?
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Registration number"
                    value={regNumber ? regNumber : ''}
                    onChange={onChange}
                />
                :
                <>
                    <Typography component="p" variant="caption" color="textSecondary">
                        Registration number:
                    </Typography>
                    <Typography component="p" variant="caption">
                        {regNumber}
                    </Typography>
                </>
            }

        </Box>
    );
};

export default MyDogCardRegNumber;
