import React, {ChangeEvent} from 'react';
import {Box, TextField, Typography} from "@mui/material";
import _ from "lodash";
import { Skeleton } from '@mui/material';

interface MyProtocolCardDescriptionProps {
    edit: boolean,
    description: string,
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const MyProtocolCardDescription = ({edit, description, onChange}: MyProtocolCardDescriptionProps) => {
    if (_.isEmpty(description)) return <Skeleton/>;

    return edit ?
        <Box mb={2}>
            <TextField
                variant="outlined"
                multiline
                rows={10}
                label="Description"
                fullWidth
                value={description}
                onChange={onChange}
            />
        </Box>
        :
        <Typography variant="subtitle1"
                    color="textPrimary"
                    component="p"
                    style={{whiteSpace: 'pre-line'}}>
            {description}
        </Typography>
};

export default MyProtocolCardDescription;
