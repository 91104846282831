import React, {useState} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import PageTitle from "../../components/common/PageTitle";
import {ReduxFirestoreQuerySetting, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import {TrainingSession} from "../../models/TrainingSession";
import _ from "lodash";
import DogSelector from "../../components/log/DogSelector";
import ProtocolSelector from "../../components/log/ProtocolSelector";
import DogTotals from "../../components/analytics/DogTotals";
import ProtocolTotals from "../../components/analytics/ProtocolTotals";
import {SessionTrialUtilities} from "../../utilities/SessionTrialUtilities";
import TrendDiagram from "../../components/analytics/TrendDiagram";
import SuccessRateDiagram from "../../components/analytics/SuccessRateDiagram";
import CalenderDiagram from "../../components/analytics/CalenderDiagram";


const AnalyticsPage = () => {
    const [selectedDog, setSelectedDog] = useState<string>("");
    const [selectedProtocol, setSelectedProtocol] = useState<string>("");
    const [selectedTrial, setSelectedTrial] = useState<string>("");
    const auth: any = useSelector<RootState>(state => state.firebase.auth);

    const getFirestoreQuery = () => {
        const queriesConfig: ReduxFirestoreQuerySetting[] = [];

        !_.isEmpty(selectedDog) && queriesConfig.push({
            collection: 'users',
            doc: auth.uid,
            subcollections: [{collection: 'training_sessions'}],
            orderBy: ['startTime', 'desc'],
            where: [['dogId', '==', selectedDog]],
            storeAs: 'training_sessions'
        });


        return queriesConfig;
    }

    useFirestoreConnect(getFirestoreQuery());

    const trainingSessions: TrainingSession[] = useSelector((state: RootState) => state.firestore.ordered['training_sessions']);


    function onSelectDog(id: string) {
        setSelectedTrial("");
        setSelectedProtocol("");
        setSelectedDog(id);
    }

    return (
        <Box>
            <PageTitle title="Analytics"/>

            <Box minHeight={200} border={1} pt={1} p={2} sx={{ borderRadius: 1 }}
                 borderColor="grey.300">
                <Typography variant="subtitle1">Filter</Typography>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={4}>
                        <DogSelector
                            selectedDog={selectedDog}
                            onSelect={(event) => onSelectDog(event.currentTarget.id)}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ProtocolSelector
                            selectedProtocol={selectedProtocol}
                            trainingSessions={trainingSessions}
                            selectedDog={selectedDog}
                            onSelectProtocol={(event => {
                                setSelectedProtocol(event.currentTarget.id);
                                setSelectedTrial('');
                            })}
                            onSelectTrial={(event => setSelectedTrial(event.currentTarget.id))}
                            selectedTrial={selectedTrial}
                            onClearProtocol={(event => {
                                setSelectedProtocol('');
                                setSelectedTrial('');
                            })}
                            onClearTrial={(event) => setSelectedTrial('')}
                        />
                    </Grid>
                </Grid>
            </Box>

            {(selectedDog && trainingSessions && !selectedProtocol) &&
            <>
                <DogTotals trainingSessions={trainingSessions}/>
                <CalenderDiagram data={
                    trainingSessions
                        .map(session => [session.startTime.toDate(), SessionTrialUtilities.getPercentage(session)])}/>

                <TrendDiagram data={trainingSessions
                    .slice()
                    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
                    .map((session, index) => [index + 1, SessionTrialUtilities.getPercentage(session)])}
                />
                <SuccessRateDiagram data={trainingSessions
                    .slice()
                    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
                    .map((session, index) => [index + 1, SessionTrialUtilities.getPercentage(session), 80])}/>
            </>
            }

            {(trainingSessions && selectedProtocol && !selectedTrial) &&
            <>
                <ProtocolTotals
                    trainingSessions={trainingSessions.filter(session => session.protocol.id === selectedProtocol)}/>
                <CalenderDiagram data={
                    trainingSessions
                        .filter(session => session.protocol.id === selectedProtocol)
                        .map(session => [session.startTime.toDate(), SessionTrialUtilities.getPercentage(session)])}/>

                <TrendDiagram data={trainingSessions
                    .filter(session => session.protocol.id === selectedProtocol)
                    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
                    .map((session, index) => [index + 1, SessionTrialUtilities.getPercentage(session)])}
                />
                <SuccessRateDiagram data={trainingSessions
                    .filter(session => session.protocol.id === selectedProtocol)
                    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
                    .map((session, index) => [index + 1, SessionTrialUtilities.getPercentage(session), 80])}/>
            </>
            }

            {(trainingSessions && selectedTrial) &&
            <>
                <ProtocolTotals trainingSessions={trainingSessions
                    .filter(session => session.protocol.id === selectedProtocol)
                    .filter(session => session.trial.id === selectedTrial)
                }/>
                <CalenderDiagram data={
                    trainingSessions
                        .filter(session => session.protocol.id === selectedProtocol)
                        .filter(session => session.trial.id === selectedTrial)
                        .map(session => [session.startTime.toDate(), SessionTrialUtilities.getPercentage(session)])}/>

                <TrendDiagram data={trainingSessions
                    .filter(session => session.protocol.id === selectedProtocol)
                    .filter(session => session.trial.id === selectedTrial)
                    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
                    .map((session, index) => [index + 1, SessionTrialUtilities.getPercentage(session)])}
                />
                <SuccessRateDiagram data={trainingSessions
                    .filter(session => session.protocol.id === selectedProtocol)
                    .filter(session => session.trial.id === selectedTrial)
                    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
                    .map((session, index) => [index + 1, SessionTrialUtilities.getPercentage(session), 80])}/>
            </>
            }


        </Box>
    );
};

export default AnalyticsPage;

/*

 */
