import React from 'react';
import {TrainingSession} from "../../models/TrainingSession";
import {Box, Grid, Typography} from "@mui/material";
import DateDifference from "../../utilities/DateDifference";
import {SessionTrialUtilities} from "../../utilities/SessionTrialUtilities";
import ViewItem from "./ViewItem";

interface SummaryViewProps {
    trainingSession: TrainingSession
}


const SummaryView = ({trainingSession}: SummaryViewProps) => {


    return (
              <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>
                  <Typography variant="h6">Summary</Typography>

                  <Grid container>

                      <Grid item xs={6}>
                          <ViewItem title="Start time"
                                    value={trainingSession.startTime.toDate().toLocaleString()}/>
                          <ViewItem title="End time"
                                    value={trainingSession.endTime.toDate().toLocaleString()}/>
                          <ViewItem title="Duration"
                                    value={new DateDifference(trainingSession.startTime.toMillis(), trainingSession.endTime.toMillis()).getFormatted()}/>
                          <ViewItem title="Repetitions" value={trainingSession.trial.trialCount}/>
                          <ViewItem title="Succeeded repetitions"
                                    value={trainingSession.trials.filter(trial => trial.success).length}/>
                      </Grid>
                      <Grid item xs={6}>
                          <ViewItem title="Failed repetitions"
                                    value={trainingSession.trials.filter(trial => !trial.success).length}/>
                          <ViewItem title="Success rate"
                                    value={SessionTrialUtilities.getPercentage(trainingSession)}/>
                          <ViewItem title="Avg time between trials"
                                    value={SessionTrialUtilities.getAvgTimeBetweenTrials(trainingSession.trials)}/>
                          <ViewItem title="Max time between trials"
                                    value={SessionTrialUtilities.getMaxTimeBetweenTrials(trainingSession.trials)}/>
                          <ViewItem title="Min time between trials"
                                    value={SessionTrialUtilities.getMinTimeBetweenTrials(trainingSession.trials)}/>
                      </Grid>
                  </Grid>
              </Box>
    );
};

export default SummaryView;
