import React from 'react';
import { Box, Chip, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {TrainingSession} from "../../models/TrainingSession";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);
interface EnvironmentViewProps {
    trainingSession: TrainingSession
}
const EnvironmentView = ({trainingSession}: EnvironmentViewProps) => {
    const classes = useStyles();
    return (
        <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>
            <Typography variant="h6">Environment</Typography>

            <div className={classes.root}>
                {
                    trainingSession.environments.map(environment => (
                        <Chip key={environment} label={environment} variant="outlined"/>
                    ))
                }
            </div>

        </Box>
    );
};

export default EnvironmentView;
