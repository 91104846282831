export default class DateDifference {
    dateFrom: number
    dateTo: number


    constructor(dateFrom: number, dateTo: number) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
    }

    millisecondsTotal(): number {
        return this.dateTo - this.dateFrom;
    }

    secondsTotal(): number {
        return Math.floor(this.millisecondsTotal() / 1000);
    }

    minutesTotal(): number {
        return Math.floor(this.secondsTotal() / 60);
    }

    hoursTotal(): number {
        return Math.floor(this.minutesTotal() / 60);
    }

    secondsFormatted(): string {
        return (this.secondsTotal() - (this.minutesTotal() * 60)).toString().padStart(2, '0');
    }

    minutesFormatted(): string {
        return (this.minutesTotal() - (this.hoursTotal() * 60)).toString().padStart(2, '0');
    }

    hoursFormatted(): string {
        return this.hoursTotal().toString().padStart(2, '0');
    }

    getFormatted(): string {
        return `${this.hoursFormatted()}:${this.minutesFormatted()}:${this.secondsFormatted()}`;
    }


}
