import React, {useState} from 'react';
import PageTitle from "../../components/common/PageTitle";
import MapOverview from "../../components/dashboard/MapOverview";
import {Button, ButtonGroup} from "@mui/material";
import TotalsOverview from "../../components/dashboard/TotalsOverview";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import {TrainingSession} from "../../models/TrainingSession";
import {isEmpty, useFirestoreConnect} from "react-redux-firebase";


const NAVIGATION_MAP: number = 1;
const NAVIGATION_TOTALS: number = 2;

const DashboardPage = () => {

    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const trainingSessions: TrainingSession[] = useSelector((state: RootState) => state.firestore.ordered['training_sessions']);
    const [navigation, setNavigation] = useState<number>(NAVIGATION_MAP);

    useFirestoreConnect({
        collection: 'users',
        doc: auth.uid,
        subcollections: [{collection: 'training_sessions'}],
        orderBy: ['startTime', 'desc'],
        storeAs: 'training_sessions'
    });

    if (isEmpty(trainingSessions)) return <div/>;


    return (
        <div>
            <PageTitle title="Dashboard">
                <ButtonGroup aria-label="outlined primary button group">
                    <Button
                        onClick={() => setNavigation(NAVIGATION_MAP)}
                        color="primary"
                        variant={navigation === NAVIGATION_MAP ? "contained": "outlined"}
                        disableElevation
                    >Map</Button>
                    <Button
                        color="primary"
                        onClick={() => setNavigation(NAVIGATION_TOTALS)}
                        variant={navigation === NAVIGATION_TOTALS ? "contained": "outlined"}
                        disableElevation
                    >Totals</Button>
                </ButtonGroup>
            </PageTitle>
            {navigation === NAVIGATION_MAP ? <MapOverview trainingSessions={trainingSessions}/> :
                <TotalsOverview trainingSessions={trainingSessions}/>}
        </div>
    );
};

export default DashboardPage;
