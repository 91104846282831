import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box, Theme} from "@mui/material";

import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            height: 1,
            '&:hover': {
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                backgroundColor: theme.palette.text.disabled,
            },
            '&::after': {
                content: '"+"',
                height: 30,
                width: 30,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.main,
                borderRadius: '50% 50% 50% 50%',
                position: 'relative',
                right: -28,
                fontSize: '1.2em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999,
            },

            '&:hover:after': {
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                backgroundColor: theme.palette.primary.dark,
            }

        },
    }),
);

interface TrialAddButtonProps {
    width?: number | string
    onClick: () => void
}

const TrialAddButton = ({width = '100%', onClick}: TrialAddButtonProps) => {
    const classes = useStyles();
    return (
        <Box display="flex"
             justifyContent="center "
             onClick={onClick}
             mt={2}
             mb={2}
             mr={2}
        >
            <Box
                className={classes.button}
                width={width}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                borderRadius="2px"
                bgcolor="text.disabled"
            />
        </Box>
    );
};

export default TrialAddButton;
