import React from 'react';
import {TrainingSession} from "../../models/TrainingSession";
import {Grid} from "@mui/material";
import TotalsCard from "./TotalsCard";
import {SessionTrialUtilities} from "../../utilities/SessionTrialUtilities";

interface StatisticsOverviewProps {
    trainingSessions: TrainingSession[]
}

const TotalsOverview = ({trainingSessions}: StatisticsOverviewProps) => {

    const getTrialTotals = () => {
        return trainingSessions
            .map(session => session.trials.length)
            .reduce((a, b) => a + b);
    };
    return (
        <Grid container spacing={2}>

            <TotalsCard
                stats={`${SessionTrialUtilities.getTotalPercentage(trainingSessions).toFixed(2)}%`}
                title="Total success rate"
                xs={12}
                borderColor={SessionTrialUtilities.getTotalPercentage(trainingSessions) >= 80 ? "success.main" : "error.main"}
            />
            <TotalsCard stats={trainingSessions.length}
                        title="Total number of training sessions"/>

            <TotalsCard stats={getTrialTotals()} title="Total number of trials"/>
        </Grid>
    );
};

export default TotalsOverview;
