import React from 'react';
import {Avatar, Box, Chip, Typography} from "@mui/material";
import {isEmpty, useFirestoreConnect} from "react-redux-firebase";
import {Dog} from "../../models/Dog";
import {useSelector} from "react-redux";
import {RootState} from "../../App";

type DogSelectorProps = {
    selectedDog: string,
    onSelect: (event: React.MouseEvent<HTMLElement>) => void
}

const DogSelector = ({selectedDog, onSelect}: DogSelectorProps) => {

    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const dogs: Dog[] = useSelector((state: RootState) => state.firestore.ordered['dogs']);

    useFirestoreConnect({
        collection: 'users',
        doc: auth.uid,
        subcollections: [{collection: 'dogs'}],
        orderBy: ['name', 'desc'],
        storeAs: 'dogs'
    });

    return (
        <Box>
            <Typography variant="caption">Select a dog</Typography>
            <Box display="flex" flexWrap="wrap">
                {
                    !isEmpty(dogs) &&
                    dogs.map((dog) => (
                        <Box key={dog.id} mr={1} mt={1}>
                            <Chip
                                avatar={<Avatar alt={dog.name} src={dog.image}/>}
                                label={dog.name}
                                id={dog.id}
                                variant={selectedDog === dog.id ? "filled" : "outlined"}
                                color="primary"
                                clickable={true}
                                onClick={onSelect}
                            />
                        </Box>
                    ))
                }
            </Box>
        </Box>
    );
};

export default DogSelector;
