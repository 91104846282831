import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import ViewItem from "./ViewItem";
import {Trial} from "../../models/Protocol";

interface TrialViewProps {
    trial: Trial
}

const TrialView = ({trial}: TrialViewProps) => {
    return (
        <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>
            <Typography variant="h6">Trial</Typography>

            <Grid container>

                <Grid item xs={6}>
                    <ViewItem title="Title"
                              value={trial.title}/>
                    <ViewItem title="Criteria"
                              value={trial.criteriaDescription}/>
                    <ViewItem title="Reset"
                              value={trial.resetDescription}/>

                    <ViewItem title="On leash"
                              value={String(trial.onLeash)}/>
                </Grid>
                <Grid item xs={6}>
                    <ViewItem title="Cue" value={trial.cue}/>
                    <ViewItem title="Trainer position"
                              value={trial.trainerPosition}/>
                    <ViewItem title="Distance between trainer and dog"
                              value={trial.trainerDistance}/>
                    <ViewItem title="Trial count"
                              value={trial.trialCount}/>

                </Grid>
            </Grid>
        </Box>
    );
};

export default TrialView;
