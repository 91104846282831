import React from 'react';
import {Box} from "@mui/material";

interface Props {
    space?: number
}
const HorizontalSpacer = ({space = 1}: Props) => {
    return <Box mt={space}/>;
};

export default HorizontalSpacer;
