import React, {ChangeEvent} from 'react';
import {TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";

interface MyDogCardTitleProps {
    name: string
    edit: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const MyDogCardTitle = ({name, edit, onChange}: MyDogCardTitleProps) => {
    return (
        <Box>
            {
                edit ?
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={onChange}
                    />
                    : <Typography variant="h5">
                        {name}
                    </Typography>
            }
        </Box>
    );
};

export default MyDogCardTitle;
