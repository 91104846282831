import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import TopAppBar from "./TopAppBar";
import LeftNavigation from "./LeftNavigation";
import Routes from "./Routes";
import {useFirestoreConnect} from "react-redux-firebase";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex'
        },
        main: {
            padding: theme.spacing(12),
            paddingTop: theme.spacing(16),
            minHeight: '100vh',
            width: 1160,
        }
    }),
);

const Scaffold = () => {
    const classes = useStyles();

    useFirestoreConnect([
        {
            collection: 'defaults',
            doc: 'defaults',
        }
    ]);

    return (

        <div className={classes.root}>
            <TopAppBar/>
            <LeftNavigation/>
            <main className={classes.main}>
                <Routes/>
            </main>
        </div>
    );
};

export default Scaffold;
