import React, {useState} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import PageTitle from "../../components/common/PageTitle";
import {isEmpty, ReduxFirestoreQuerySetting, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import {TrainingSession} from "../../models/TrainingSession";
import _ from "lodash";
import DogSelector from "../../components/log/DogSelector";
import ProtocolSelector from "../../components/log/ProtocolSelector";
import HorizontalSpacer from "../../components/common/HorizontalSpacer";
import LogCard from "../../components/log/LogCard";
import {LoadScript} from "@react-google-maps/api";


const LogPage = () => {
    const [selectedDog, setSelectedDog] = useState<string>("");
    const [selectedProtocol, setSelectedProtocol] = useState<string>("");
    const [selectedTrial, setSelectedTrial] = useState<string>("");
    const auth: any = useSelector<RootState>(state => state.firebase.auth);

    const getFirestoreQuery = () => {
        const queriesConfig: ReduxFirestoreQuerySetting[] = [];

        !_.isEmpty(selectedDog) && queriesConfig.push({
            collection: 'users',
            doc: auth.uid,
            subcollections: [{collection: 'training_sessions'}],
            orderBy: ['startTime', 'desc'],
            where: [['dogId', '==', selectedDog]],
            storeAs: 'training_sessions'
        });


        return queriesConfig;
    }

    useFirestoreConnect(getFirestoreQuery());

    const trainingSessions: TrainingSession[] = useSelector((state: RootState) => state.firestore.ordered['training_sessions']);


    function onSelectDog(id: string) {
        setSelectedTrial("");
        setSelectedProtocol("");
        setSelectedDog(id);
    }

    return (
        <Box>
            <PageTitle title="Logs"/>

            <Box minHeight={200} border={1} pt={1} p={2} sx={{ borderRadius: 1 }}
                 borderColor="grey.300">
                <Typography variant="subtitle1">Filter</Typography>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={4}>
                        <DogSelector
                            selectedDog={selectedDog}
                            onSelect={(event) => onSelectDog(event.currentTarget.id)}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ProtocolSelector
                            selectedProtocol={selectedProtocol}
                            trainingSessions={trainingSessions}
                            selectedDog={selectedDog}
                            onSelectProtocol={(event => setSelectedProtocol(event.currentTarget.id))}
                            onSelectTrial={(event => setSelectedTrial(event.currentTarget.id))}
                            selectedTrial={selectedTrial}
                            onClearProtocol={(event => {
                                setSelectedProtocol('');
                                setSelectedTrial('');
                            })}
                            onClearTrial={(event) => setSelectedTrial('')}
                        />
                    </Grid>
                </Grid>
            </Box>

            <HorizontalSpacer space={2}/>
            <Box mb={4}>
                <LoadScript googleMapsApiKey="AIzaSyBb1Mme76KpFhfR6nnMV1Eu9UpKM6wNKi4">
                    {
                        !isEmpty(trainingSessions) &&
                        trainingSessions
                            .filter(trainingSession => trainingSession.protocol.id === selectedProtocol)
                            .filter(trainingSession => _.isEmpty(selectedTrial) ? true : trainingSession.trial.id === selectedTrial)
                            .map((trainingSession: TrainingSession) => (
                                <LogCard key={trainingSession.id}
                                         trainingSession={trainingSession}/>
                            ))

                    }
                </LoadScript>
            </Box>

        </Box>
    );
};

export default LogPage;
