import {SessionTrial, TrainingSession} from "../models/TrainingSession";
import DateDifference from "./DateDifference";

export class SessionTrialUtilities {

    static isSuccess = (trainingSession: TrainingSession): boolean =>
        SessionTrialUtilities.getPercentage(trainingSession) >= 80;


    static getPercentage = (trainingSession: TrainingSession): number =>
        ((trainingSession.trials.filter(trial => trial.success).length / trainingSession.trials.length) * 100);

    static getTotalPercentage = (trainingSessions: TrainingSession[]): number => {
        if (trainingSessions.length > 0) {
            return trainingSessions
                .map(trainingSession => SessionTrialUtilities.getPercentage(trainingSession))
                .reduce((a, b) => a + b) / trainingSessions.length;
        }
        return 0;
    }

    static getTrialTimeDifferences = (trials: SessionTrial[]): number[] => {
        const timeDifference: number[] = []
        for (let i = 0; i < trials.length - 1; i++) {
            const trial = trials[i];
            const nextTrial = trials[i + 1];
            timeDifference.push(new DateDifference(trial.dateTime.toMillis(), nextTrial.dateTime.toMillis()).secondsTotal());
        }

        return timeDifference.sort();
    }

    static getAvgTimeBetweenTrials = (trials: SessionTrial[]): number => {
        const sum: number = SessionTrialUtilities.getTrialTimeDifferences(trials).reduce((a, b) => a + b, 0)
        return Math.round(sum / trials.length);
    }

    static getMinTimeBetweenTrials = (trials: SessionTrial[]): number => {
        return Math.min(...SessionTrialUtilities.getTrialTimeDifferences(trials));
    }

    static getMaxTimeBetweenTrials = (trials: SessionTrial[]): number => {
        return Math.max(...SessionTrialUtilities.getTrialTimeDifferences(trials));
    }

    static getTotalTrainingTimeInMilliseconds = (trainingSessions: TrainingSession[]): number => {
        if (trainingSessions.length > 0) {
            return trainingSessions
                .map(ts => new DateDifference(ts.startTime.toMillis(), ts.endTime.toMillis()))
                .map(ts => ts.millisecondsTotal())
                .reduce((a, b) => a + b);
        }
        return 0;
    }

    static getTotalTrainingTimeFormatted = (trainingSessions: TrainingSession[]): string => {

        const timeObject = convertMS(SessionTrialUtilities.getTotalTrainingTimeInMilliseconds(trainingSessions));
        return `${timeObject.day.toString()} days `
        + `${timeObject.hour.toString()} hours `
            + `${timeObject.minute.toString()} minutes `
            + `${timeObject.seconds.toString()} seconds`;
    }



}

interface TimeObject {
    day: number,
    hour: number,
    minute: number,
    seconds: number
}
function convertMS( milliseconds: number ) : TimeObject {
    let day, hour, minute, seconds : number;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: day,
        hour: hour,
        minute: minute,
        seconds: seconds
    };
}
