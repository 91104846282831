import React from 'react';
import {Box} from "@mui/material";
import {SessionTrial} from "../../models/TrainingSession";
import SuccessIcon from "@mui/icons-material/ThumbUpAltRounded";
import FailedIcon from "@mui/icons-material/ThumbDownAltRounded";

interface SessionTrialSuccessIndicatorProps {
    trainingSession: SessionTrial
}

const SessionTrialSuccessIndicator = ({trainingSession}: SessionTrialSuccessIndicatorProps) => {

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={48}
            width={48}
            borderRadius="50%"
            bgcolor={trainingSession.success ? "success.main" : "error.main"}
            color="white"
        >
            {trainingSession.success ? <SuccessIcon/> : <FailedIcon/>}
        </Box>
    );
};

export default SessionTrialSuccessIndicator;
