import React, {useEffect, useState} from 'react';
import PageTitle from "../../components/common/PageTitle";
import {Box, Button} from "@mui/material";
import AddIcon from '@mui/icons-material/AddRounded';
import NewProtocolDialog from "../../components/my-protocols-add/NewProtocolDialog";
import {isEmpty, isLoaded, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../App";
import {MyProtocol} from "../../models/Protocol";
import MyProtocolCard from "../../components/my-protocol-card/MyProtocolCard";
import {startGlobalLoading, stopGlobalLoading} from "../../data/redux/actions/actions";


const MyProtocolsPage = () => {
    const [newProtocolOpen, setNewProtocolOpen] = useState<boolean>(false);
    const [newProtocol, setNewProtocol] = useState<MyProtocol | undefined>();
    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const dispatch = useDispatch();

    const userProtocols = useFirestore()
        .collection('users')
        .doc(auth.uid)
        .collection('protocols');

    const onSaveNewProtocol = (protocol: MyProtocol) => {
        setNewProtocol({id: 'dummy', title: '', description: ''});
        setNewProtocolOpen(false);
        userProtocols.add(protocol).then(() => {
            setNewProtocol(undefined);
        });

    }

    const onCancelNewProtocol = () => {
        setNewProtocolOpen(false);
    }

    useFirestoreConnect([
        {
            collection: 'users',
            doc: auth.uid,
            subcollections: [{collection: 'protocols'}],
            orderBy: ['lastModified', 'desc'],
            storeAs: 'protocols'
        }
    ]);
    const protocols = useSelector((state: RootState) => state.firestore.ordered['protocols']);

    useEffect(() => {
        isLoaded(protocols) ? dispatch(stopGlobalLoading()) : dispatch(startGlobalLoading());
    }, [protocols, dispatch]);

    return (
        <Box>
            <NewProtocolDialog isOpen={newProtocolOpen}
                               onSave={onSaveNewProtocol}
                               onCancel={onCancelNewProtocol}
            />
            <PageTitle title="My Protocols">
                <Button startIcon={<AddIcon/>}
                        variant="contained"
                        color="primary"
                        onClick={() => setNewProtocolOpen(true)}>
                    Add Protocol
                </Button>
            </PageTitle>
            <Box>
                {
                    newProtocol && <MyProtocolCard key={newProtocol.id} protocol={newProtocol}/>
                }
                {
                    !isEmpty(protocols) &&
                    Object.values<MyProtocol>(protocols).map(protocol => (
                        <MyProtocolCard key={protocol.id} protocol={protocol}/>
                    ))
                }
            </Box>
        </Box>
    );
};

export default MyProtocolsPage;
