import React from 'react';
import {Avatar, Box, Chip, Typography} from "@mui/material";
import {isEmpty, useFirestoreConnect} from "react-redux-firebase";
import {Trial} from "../../models/Protocol";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import _ from "lodash";
import {TrainingSession} from "../../models/TrainingSession";

interface TrialSelectorProps {
    selectedTrial: string
    protocolId: string
    onSelect: (event: React.MouseEvent<HTMLElement>) => void
    onClear: (event: React.MouseEvent<HTMLElement>) => void
    trainingSessions: TrainingSession[]
    selectedDog: string
}

const TrialSelector = ({selectedTrial, protocolId, onSelect, onClear, trainingSessions, selectedDog}: TrialSelectorProps) => {
    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const trials: Trial[] = useSelector((state: RootState) => state.firestore.ordered['trials']);

    useFirestoreConnect(!_.isEmpty(protocolId) ? {
        collection: `users/${auth.uid}/protocols`,
        doc: protocolId,
        subcollections: [{collection: 'trials'}],
        orderBy: ['order', 'asc'],
        storeAs: 'trials'
    } : undefined);

    const hasTrainingSessions = (trialId: string | undefined) => {
        if (trainingSessions === undefined) return false;
        return trainingSessions
            .filter((trainingSession) => trainingSession.protocol.id === protocolId)
            .filter(trainingSession => trainingSession.trial.id === trialId)
            .filter(trainingSessions => trainingSessions.dogId === selectedDog)
            .length > 0;
    }

    if (_.isEmpty(protocolId)) return <div/>
    return (
        <Box>
            <Typography variant="caption">Select a trial</Typography>
            <Box display="flex" flexWrap="wrap">
                {
                    !isEmpty(trials) &&
                    trials
                        .map((trial) => (
                        <Box key={trial.id} mr={1} mt={1}>
                            <Chip
                                label={trial.title}
                                id={trial.id}
                                variant={selectedTrial === trial.id ? "filled" : "outlined"}
                                color={"primary"}
                                clickable={true}
                                onClick={onSelect}
                                avatar={<Avatar>{trial.order}</Avatar>}
                                onDelete={onClear}
                                disabled={!hasTrainingSessions(trial?.id)}
                            />
                        </Box>
                    ))
                }
            </Box>
        </Box>

    );
};

export default TrialSelector;
