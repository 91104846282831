import React, {ChangeEvent, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import EditIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import makeStyles from '@mui/styles/makeStyles';
import {Trial} from "../../models/Protocol";
import TrialForm from "./TrialForm";
import VerticalSpacer from "../common/VerticalSpacer";
import {useFirestore} from "react-redux-firebase";
import ConfirmDialog from "../common/ConfirmDialog";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import TrialAddButton from "../trial-add-button/TrialAddButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orderAvatar: {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
        },
        listItem: {
            '&:hover': {
                backgroundColor: theme.palette.grey["100"],
                borderRadius: theme.shape.borderRadius,
            },
        },
        listItemContainer: {
            marginRight: theme.spacing(2)
        }

    }),
);

interface TrialListItemProps {
    trial: Trial,
    protocolId?: string
    onAddTrail: () => void

}

const TrialListItem = ({trial, protocolId, onAddTrail}: TrialListItemProps) => {
    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const [edit, setEdit] = useState<boolean>(false);
    const [trialEdit, setTrialEdit] = useState<Trial>(trial);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    const classes = useStyles();

    const trialDocument = useFirestore()
        .collection('users')
        .doc(auth.uid)
        .collection('protocols')
        .doc(protocolId)
        .collection('trials')
        .doc(trial.id);

    const onUpdateTrial = (e: ChangeEvent<HTMLInputElement>) => {
        let value;
        if (e.target.type === 'text' || e.target.type === 'textarea') {
            value = e.target.value;
        }
        if (e.target.type === 'number') {
            value = parseInt(e.target.value, 10);
        }
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        }
        setTrialEdit({...trialEdit, [e.target.id]: value});
    }

    const onSave = () => {
        setEdit(false)
        trialDocument.update(trialEdit).then(() => console.log('trial updated', trialEdit));
    }

    const onDelete = () => {
        trialDocument.delete()
            .then(() => console.log(''))
            .finally(() => setShowConfirmDialog(false));

    }

    return edit ?
        <Box
            border={1}
            borderRadius={1}
            borderColor="grey.300"
            p={2}
            mb={2}
            mt={3}
            width="100%">
            <TrialForm trial={trialEdit} onUpdate={onUpdateTrial}/>
            <Box mt={4} display="flex" justifyContent="flex-end">
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => setEdit(false)}>
                    Cancel
                </Button>
                <VerticalSpacer/>
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => onSave()}>
                    Save
                </Button>
            </Box>
        </Box>
        :
        <>
            <ConfirmDialog
                title="Delete trial?"
                message="Do you really want to delete the trial?"
                onConfirm={onDelete}
                onNotConfirm={() => setShowConfirmDialog(false)}
                open={showConfirmDialog}
            />
            <ListItem className={classes.listItem}  ContainerProps={{className: classes.listItemContainer}}>
                <ListItemAvatar>
                    <Avatar className={classes.orderAvatar}>{trial.order}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={trial.title}
                    secondary={trial.criteriaDescription}
                />
                <ListItemSecondaryAction >
                    <IconButton edge="end" aria-label="delete" onClick={() => setEdit(true)} size="large">
                        <EditIcon/>
                    </IconButton>
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setShowConfirmDialog(true)}
                        size="large">
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <TrialAddButton onClick={onAddTrail}/>
        </>
};

export default TrialListItem;
