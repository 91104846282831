import React from 'react';
import {Box} from "@mui/material";
import {TrainingSession} from "../../models/TrainingSession";
import {SessionTrialUtilities} from "../../utilities/SessionTrialUtilities";

interface SessionSuccessIndicatorProps {
    trainingSession: TrainingSession
}

const SessionSuccessIndicator = ({trainingSession}: SessionSuccessIndicatorProps) => {

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={48}
            width={48}
            borderRadius="50%"
            bgcolor={SessionTrialUtilities.isSuccess(trainingSession) ? "success.main" : "error.main"}
            color="white"
        >
            {SessionTrialUtilities.getPercentage(trainingSession).toFixed(1)}
        </Box>
    );
};

export default SessionSuccessIndicator;
