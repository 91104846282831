import React from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Chip,
    LinearProgress,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import {useFirebase} from "react-redux-firebase";
import {Link} from 'react-router-dom';
import l4dLogo from '../../assets/logo-rectangle-white.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            marginLeft: 20,
            color: "inherit",
            textDecoration: "none"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },

        avatar: {
            margin: 10,
        }
    }),
);

const TopAppBar = () => {
    const classes = useStyles();
    const firebase = useFirebase();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const auth: any = useSelector<RootState>(state => state.firebase.auth);


    const isLoading = useSelector<RootState, boolean>(state => state.app.isLoading);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = async () => {
        await firebase.logout();
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" alignItems="center" width={1}>
                    <img src={l4dLogo} alt="Logs4dogs logo" height={48} className={classes.title}/>

                    <Box display="flex" alignItems="center" flexDirection="row">
                        <Box mr={2}>
                            {process.env.REACT_APP_DEV && <Chip color="warning" label="DEV" disabled />}
                        </Box>
                        <Typography variant="body1" noWrap>
                            {auth.displayName}
                        </Typography>
                        <Avatar alt="Remy Sharp" onClick={handleMenu}
                                src={auth.photoURL} className={classes.avatar}/>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={profileMenuOpen}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={logout} component={Link} to="/">Logout</MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </Toolbar>
            {isLoading && <LinearProgress/>}
        </AppBar>
    );
};

export default TopAppBar;
