import React from 'react';
import {Box, Chip, Typography} from "@mui/material";
import {isEmpty, useFirestoreConnect} from "react-redux-firebase";
import {MyProtocol} from "../../models/Protocol";
import {TrainingSession} from "../../models/TrainingSession";
import TrialSelector from "./TrialSelector";
import {useSelector} from "react-redux";
import {RootState} from "../../App";
import _ from "lodash";

interface ProtocolSelectorProps {
    selectedProtocol: string
    selectedDog: string
    selectedTrial: string
    trainingSessions: TrainingSession[]
    onSelectProtocol: (event: React.MouseEvent<HTMLElement>) => void
    onSelectTrial: (event: React.MouseEvent<HTMLElement>) => void
    onClearProtocol: (event: React.MouseEvent<HTMLElement>) => void
    onClearTrial: (event: React.MouseEvent<HTMLElement>) => void
}

const ProtocolSelector = ({selectedProtocol, selectedTrial, trainingSessions, onSelectProtocol, selectedDog, onSelectTrial, onClearProtocol, onClearTrial}: ProtocolSelectorProps) => {
    const auth: any = useSelector<RootState>(state => state.firebase.auth)
    const protocols: MyProtocol[] = useSelector((state: RootState) => state.firestore.ordered['protocols']);

    useFirestoreConnect({
        collection: 'users',
        doc: auth.uid,
        subcollections: [{collection: 'protocols'}],
        orderBy: ['lastModified', 'desc'],
        storeAs: 'protocols'
    });

    const hasTrainingSessions = (protocolId: string) => {
        if (trainingSessions === undefined) return false;
        return trainingSessions
            .filter((trainingSession) => trainingSession.protocol.id === protocolId)
            .filter(trainingSessions => trainingSessions.dogId === selectedDog)
            .length > 0;
    }

    if (_.isEmpty(selectedDog)) return <div/>

    return (
        <Box>
            <Typography variant="caption">Select a protocol</Typography>
            <Box mb={1} display="flex" flexWrap="wrap">
                {
                    !isEmpty(protocols) &&
                    protocols.map((protocol) => (
                        <Box key={protocol.id} mr={1} mt={1}>
                            <Chip
                                label={protocol.title}
                                id={protocol.id}
                                variant={selectedProtocol === protocol.id ? "filled" : "outlined"}
                                color={"primary"}
                                clickable={true}
                                disabled={!hasTrainingSessions(protocol.id)}
                                onClick={onSelectProtocol}
                                onDelete={onClearProtocol}
                            />
                        </Box>
                    ))
                }
            </Box>
            <TrialSelector
                protocolId={selectedProtocol}
                selectedTrial={selectedTrial}
                onSelect={onSelectTrial}
                onClear={onClearTrial}
                selectedDog={selectedDog}
                trainingSessions={trainingSessions}
            />
        </Box>
    );
};

export default ProtocolSelector;
