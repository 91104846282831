import React from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Toolbar,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import DashboardIcon from '@mui/icons-material/DashboardRounded';
import MyProtocolsIcon from '@mui/icons-material/FormatListBulletedRounded';
import DogsIcon from '@mui/icons-material/PetsRounded';
import EnvironmentIcon from '@mui/icons-material/TagRounded';
import AnalyticsIcon from '@mui/icons-material/MultilineChartRounded';
import LogsIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            drawer: {
                width: 250,
                flexShrink: 0,
            },
            drawerPaper: {
                width: 250,
            }
        }
    )
);
const LeftNavigation = () => {
    const classes = useStyles();

    return (
        <div>

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar/>
                <Box>
                    <List>
                        <ListItem button dense component={Link} to='/me/dashboard'>
                            <ListItemIcon>
                                <DashboardIcon style={{color: "#000"}}/>
                            </ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem dense>
                            <Typography variant="overline" color="textSecondary" align="left">
                                Protocols
                            </Typography>
                        </ListItem>
                        <ListItem button dense component={Link} to='/me/protocols'>
                            <ListItemIcon>
                                <MyProtocolsIcon style={{color: "#000"}}/>
                            </ListItemIcon>
                            <ListItemText primary="My protocols"/>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem dense>
                            <Typography variant="overline" color="textSecondary" align="left">
                                Training sessions
                            </Typography>
                        </ListItem>
                        <ListItem button dense component={Link} to='/me/training-sessions/analytics'>
                            <ListItemIcon>
                                <AnalyticsIcon style={{color: "#000"}}/>
                            </ListItemIcon>
                            <ListItemText primary="Analytics"/>
                        </ListItem>
                        <ListItem button dense component={Link} to='/me/training-sessions/logs'>
                            <ListItemIcon>
                                <LogsIcon style={{color: "#000"}}/>
                            </ListItemIcon>
                            <ListItemText primary="Logs"/>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem dense>
                            <Typography variant="overline" color="textSecondary" align="left">
                                Dogs
                            </Typography>
                        </ListItem>
                        <ListItem button dense component={Link} to='/me/dogs'>
                            <ListItemIcon>
                                <DogsIcon style={{color: "#000"}}/>
                            </ListItemIcon>
                            <ListItemText primary="My Dogs"/>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem dense>
                            <Typography variant="overline" color="textSecondary" align="left">
                                Settings
                            </Typography>
                        </ListItem>
                        <ListItem button dense component={Link} to='/me/settings/environments'>
                            <ListItemIcon>
                                <EnvironmentIcon style={{color: "#000"}}/>
                            </ListItemIcon>
                            <ListItemText primary="Environments"/>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </div>
    );
};

export default LeftNavigation;
