import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {MyProtocol} from "../../models/Protocol";

interface NewProtocolDialog {
    isOpen: boolean,
    onSave: (protocol: MyProtocol) => void,
    onCancel: () => void
}

const NewProtocolDialog = ({isOpen, onCancel, onSave}: NewProtocolDialog) => {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const onAdd = () => {
        onSave({
            id: 'new',
            title: title,
            description: description
        })
    };
    return (
        <Dialog fullWidth open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New Protocol</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    autoFocus
                    label="Name"
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
                <Box height="8px"/>
                <TextField
                    variant="outlined"
                    multiline
                    rows={10}
                    label="Description"
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onAdd} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewProtocolDialog;
