import React from 'react';
import {MyProtocol} from "../../models/Protocol";
import {Box, Grid, Typography} from "@mui/material";
import ViewItem from "./ViewItem";

interface ProtocolViewProps {
    protocol: MyProtocol
}

const ProtocolView = ({protocol}: ProtocolViewProps) => {
    return (
        <Box width={1} border={1} borderRadius="4px" borderColor="grey.200" p={2}>
            <Typography variant="h6">Protocol</Typography>

            <Grid container>

                <Grid item xs={6}>
                    <ViewItem title="Title"
                              value={protocol.title}/>


                </Grid>
                <Grid item xs={6}>
                    <ViewItem title="Description"
                              value={protocol.description}/>

                </Grid>
            </Grid>
        </Box>
    );
};

export default ProtocolView;
