import React, {ChangeEvent} from 'react';
import {Box, TextField, Typography} from "@mui/material";
import _ from "lodash";
import { Skeleton } from '@mui/material';

interface MyProtocolCardTitleProps {
    edit: boolean,
    title: string,
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const MyProtocolCardTitle = ({edit, title, onChange}: MyProtocolCardTitleProps) => {
    if (_.isEmpty(title)) return <Skeleton/>;
    return edit ?
        <Box mb={1}>
            <TextField
                variant="outlined"
                fullWidth
                label="Title"
                value={title}
                onChange={onChange}
            />
        </Box>
        :
        <Typography variant="h5"
                    color="textSecondary">
            {title}
        </Typography>
};

export default MyProtocolCardTitle;
